import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/block/container"
import FieldText from "../components/field/fieldText"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Page Not Found" pageClass="page--not-found" />
    <Container classes="block--page-top-content block--page-top-content--success">
      <FieldText>
        <h1 className="text text--centered">404: Not Found</h1>
        <p className="text text--centered">
          You just hit a route that doesn&#39;t exist... The sadness.
        </p>
      </FieldText>
    </Container>
  </Layout>
)

export default NotFoundPage
